import { isEmpty } from 'lodash';
import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { Image } from '@/components/image';
import { ImageCaption } from '@/components/image-caption';
import type { ImageBlockType } from '@/lib/types';

const ImageBlock = ({ images }: ImageBlockType): ReactElement | null => {
  if (isEmpty(images)) {
    return null;
  }
  return (
    <BaseBlock className="images-block">
      <div className="container">
        <div className="row images-container">
          {images.map((image) => {
            const isPortrait = image.height > image.width;
            const imageData = isPortrait ? image.portrait : image.landscape;
            return (
              <div className="col images-col" key={imageData.src}>
                <figure role="group">
                  <div className="col-12 width-narrow">
                    <div className="component-content">
                      <Image data={imageData} focalPoint={image.focalPoint} explicitWidth />
                      <ImageCaption
                        variant="Default"
                        title={imageData.title ? imageData.title : ''}
                        author={image.author}
                      />
                    </div>
                  </div>
                </figure>
              </div>
            );
          })}
        </div>
      </div>
    </BaseBlock>
  );
};

export { ImageBlock };
