import { FC } from 'react';
import { isEmpty } from 'lodash';
import type { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';

import { isStructuredTextEmpty } from '@/lib/datocms/utils/is-structured-text-empty';
import { StructuredText } from '@/components/structured-text';

type BlockHeaderType = {
  title: string;
  description?: StructuredTextType;
};

const BlockHeader: FC<BlockHeaderType> = ({ title, description = undefined }) => {
  if (isEmpty(title) && isStructuredTextEmpty(description)) {
    return null;
  }
  return (
    <div className="mb-5 mb-md-6">
      {!isEmpty(title) && <h2 className="text-center mb-4">{title}</h2>}
      {!isStructuredTextEmpty(description) && (
        <div className="module-description">
          <StructuredText data={description as StructuredTextType} />
        </div>
      )}
    </div>
  );
};

export { BlockHeader };
