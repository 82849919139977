import { createContext, useContext } from 'react';

export type BlockContextType = {
  isInline: boolean;
  isSameThemeAsPrevious?: boolean;
  isSameThemeAsNext?: boolean;
};

export const defaultBlockContext = {
  isInline: false,
  isSameThemeAsPrevious: false,
  isSameThemeAsNext: false,
};

const BlockContext = createContext<BlockContextType>(defaultBlockContext);

const useBlockContext = (): BlockContextType => useContext(BlockContext);

export { BlockContext, useBlockContext };
