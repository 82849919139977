import { trimEnd } from 'lodash';

type Model = {
  slug?: string | undefined;
  parent?: Model | null | undefined;
  categoryPage?: Model | null | undefined;
};

const generatePageUrl = (model: Model | null): string => {
  if (!model || !model.slug) {
    return '';
  }
  const parent = model.parent || model.categoryPage;
  if (!parent) {
    return `${model.slug}/`;
  }
  return `${generatePageUrl(parent) + model.slug}/`;
};

const getPageUrlString = (model: Model | null): string => trimEnd(`/${generatePageUrl(model)}`, '/');

const getPageUrlArray = (model: Model | null): string[] => trimEnd(generatePageUrl(model), '/').split('/');

export { getPageUrlString, getPageUrlArray };
