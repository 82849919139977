import { FC, ReactElement, ReactNode } from 'react';
import { kebabCase } from 'lodash';
import clsx from 'clsx';

import { Theme, Width } from '@/lib/types';
import { useBlockContext } from '@/lib/contexts/block';
import { componentContent } from '../cta-block/styles.css';

type BaseBlockProps = {
  className?: string | null;
  componentContentClass?: string | null;
  children: ReactNode;
  theme?: Theme;
  width?: null | Width;
  anchor?: string | null;
  hasContentPadding?: boolean;
};

const BaseBlock: FC<BaseBlockProps> = ({
  className = null,
  componentContentClass = null,
  children,
  theme = 'Light',
  width = null,
  anchor = null,
  hasContentPadding = true,
}): ReactElement => {
  const { isInline, isSameThemeAsPrevious, isSameThemeAsNext } = useBlockContext();
  const themeClass = `${theme === 'Mid' ? 'bg-alt' : 'col-12'}`;
  const widthClass = width ? `width-${kebabCase(width)}` : null;

  return (
    <div
      id={anchor || undefined}
      className={clsx('component content-blocks-item', themeClass, widthClass, className, {
        'is-inline': isInline,
        'is-same-theme-as-prev': isSameThemeAsPrevious,
        'is-same-theme-as-next': isSameThemeAsNext,
      })}
    >
      {/* TODO: componentContent needs to replace component-content class eventually */}
      <div
        className={clsx(componentContent, componentContentClass, 'component-content', {
          'block-content': !hasContentPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { BaseBlock };
