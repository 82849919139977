import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';

import type { WidgetBlockType } from '@/lib/types';

export type WidgetProps = {
  id?: string;
  anchor?: string | null;
};

const widgetMap = {
  'Business Roadside Cover Comparison': dynamic<WidgetProps>(() =>
    import('@/components/business-roadside-cover-comparison').then((mod) => mod.BusinessRoadsideCoverComparison)
  ),
  'Car Insurance Comparison': dynamic<WidgetProps>(() =>
    import('@/components/car-insurance-comparison').then((mod) => mod.CarInsuranceComparison)
  ),
  'Christmas Hours': dynamic<WidgetProps>(() =>
    import('@/components/christmas-hours').then((mod) => mod.ChristmasHours)
  ),
  'Download For Mobile': dynamic<WidgetProps>(() =>
    import('@/components/download-for-mobile').then((mod) => mod.DownloadForMobile)
  ),
  'Driver Training Comparison': dynamic<WidgetProps>(() =>
    import('@/components/driver-training-comparison').then((mod) => mod.DriverTrainingComparison)
  ),
  'Home Insurance Comparison': dynamic<WidgetProps>(() =>
    import('@/components/home-insurance-comparison').then((mod) => mod.HomeInsuranceComparison)
  ),
  'Key Road Funding Priorities': dynamic<WidgetProps>(() =>
    import('@/components/key-road-funding-priorities').then((mod) => mod.KeyRoadFundingPriorities)
  ),
  'Loan Calculator': dynamic<WidgetProps>(() =>
    import('@/components/loan-calculator').then((mod) => mod.LoanCalculator)
  ),
  'Reward Categories': dynamic<WidgetProps>(() =>
    import('@/components/reward-categories').then((mod) => mod.RewardCategories)
  ),
  'Roadside Assist Comparison': dynamic<WidgetProps>(() =>
    import('@/components/roadside-assist-comparison').then((mod) => mod.RoadsideAssistComparison)
  ),
  'Roadside Group Cover Comparison Table': dynamic<WidgetProps>(() =>
    import('@/components/roadside-group-cover-comparison-table').then((mod) => mod.RoadsideGroupCoverComparisonTable)
  ),
  'Social Links': dynamic<WidgetProps>(() => import('@/components/social-links').then((mod) => mod.SocialLinks)),
  'Travel Insurance (Int) Comparison': dynamic<WidgetProps>(() =>
    import('@/components/travel-ins-international-comparison').then((mod) => mod.TravelInsuranceInternationalComparison)
  ),
  'Travel Insurance (Dom) Comparison': dynamic<WidgetProps>(() =>
    import('@/components/travel-ins-domestic-comparison').then((mod) => mod.TravelInsuranceDomesticComparison)
  ),
  'Hundred Year Homepage Banner': dynamic<WidgetProps>(() =>
    import('@/components/hundred-year-homepage-banner').then((mod) => mod.HundredYearHomePageBanner)
  ),
  'Hundred Year Hundred Book Banner': dynamic<WidgetProps>(() =>
    import('@/components/hundred-year-hundred-book-banner').then((mod) => mod.HundredYearHundredBookBanner)
  ),
  'Roadside Advantage Feature Table': dynamic<WidgetProps>(() =>
    import('@/components/roadside-advantage-feature-table').then((mod) => mod.RoadsideAdvantageFeatureTable)
  ),
  'Roadside Ultimate Feature Table': dynamic<WidgetProps>(() =>
    import('@/components/roadside-ultimate-feature-table').then((mod) => mod.RoadsideUltimateFeatureTable)
  ),
};

const WidgetBlock = ({ id, widget, anchor }: WidgetBlockType): ReactElement => {
  const Widget = widgetMap[widget];

  if (!Widget) {
    return (
      <div className="component col-12">
        <div className="component-content">
          <div className="text-center">{widget} widget not found</div>
        </div>
      </div>
    );
  }

  return <Widget id={`widget-${id}`} anchor={anchor} />;
};

export { WidgetBlock };
