import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { isArray, isEmpty } from 'lodash';
import { renderMetaTags, TitleMetaLinkTag } from 'react-datocms';
import { Toaster } from 'react-hot-toast';
import Head from 'next/head';

import { AnnouncementBar } from '@/components/announcement-bar';
import { Breadcrumbs } from '@/components/breadcrumbs';
import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { isStructuredTextEmpty } from '@/lib/datocms/utils/is-structured-text-empty';
import { notify } from '@/utils/notify';
import { PagePaths } from '@/lib/types';
import { URLS } from '@/lib/constants';
import { useConfigurationContext } from '@/lib/contexts/configuration-context';

type LayoutProps = {
  children: ReactNode;
  metaTags?: null | TitleMetaLinkTag[];
  paths: PagePaths;
  site?: { favicon: TitleMetaLinkTag[] };
};

// @NOTE: This is a short term solution. This is only used for alerting content editors.
const DEFAULT_META =
  'Everything we’re about, and everything we do, is to benefit our members. Here are some of the things you can look forward to as a member with us.';

const Layout: FC<LayoutProps> = ({ children, metaTags = null, paths, site = { favicon: [] } }): ReactElement => {
  // If we have breadcrumb paths array (can be zero or any length), get the last path to use as the canonical url
  const canonical = isArray(paths) ? `${URLS.RACT_WEBSITE}${paths[paths.length - 1]?.path ?? ''}` : null;
  const configuration = useConfigurationContext();
  const currentDate = new Date();
  // Get start and end time, otherwise set to the min or max date values
  const startDate = new Date(configuration?.announcementStartTime ?? 0);
  const endDate = new Date(configuration?.announcementEndTime ?? 8640000000000000);
  const announcementContent = configuration?.announcementContent;
  const isAnnouncementBarEnabled =
    !isStructuredTextEmpty(announcementContent) && startDate <= currentDate && endDate >= currentDate;

  useEffect(() => {
    if (isEmpty(metaTags)) return;
    const metaDescription = metaTags?.find((tag) => tag?.attributes?.name === 'description');
    const metaDescriptionContent = metaDescription?.attributes?.content;
    if (isEmpty(metaDescriptionContent) || metaDescriptionContent === DEFAULT_META) {
      notify('Empty page meta description', 'error', { id: 'meta' });
    }
  }, [metaTags]);

  return (
    <div id="wrapper">
      {typeof window !== 'undefined' && <Toaster position="top-left" />}

      <Head>
        <link rel="home" href={URLS.RACT_WEBSITE} />
        {canonical && <link rel="canonical" href={canonical} key="canonical" />}
        {metaTags && renderMetaTags(metaTags.concat(site.favicon))}
      </Head>

      <Header />
      <main>
        <div id="content" className="container">
          <AnnouncementBar enabled={isAnnouncementBarEnabled} content={announcementContent} />
          <Breadcrumbs paths={paths} />
          <div className="row">{children}</div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export { Layout };
