import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { ReactElement } from 'react';
import clsx from 'clsx';

import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { menuItems } from '@/components/header/data';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const ANALYTICS_CONTEXT = 'Mega menu';

const Navigation = (): ReactElement => {
  return (
    <nav
      id="primary-navigation"
      className={clsx('primary-nav', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
    >
      <Popover.Group as="ul" className="menu-list">
        {menuItems.map((item) => {
          return (
            <Popover as="li" key={item.slug}>
              {({ open, close }) => {
                return (
                  <>
                    <Popover.Button
                      className={clsx(
                        'menu-item-link body-6 menu-toggle',
                        { expanded: open },
                        HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                      )}
                    >
                      <span>{item.title}</span>
                      <FontAwesomeIcon className="ml-2 d-lg-none" icon={faChevronRight} />
                      <FontAwesomeIcon
                        className="ml-2 d-none d-xl-inline-block"
                        icon={open ? faChevronUp : faChevronDown}
                      />
                    </Popover.Button>
                    <Popover.Overlay className="main-content-overlay" />
                    <Popover.Panel
                      className={clsx(
                        'megamenu-wrapper',
                        { show: open },
                        HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                      )}
                    >
                      <div className="megamenu container" aria-labelledby={`#${item.slug}Menu`}>
                        <div className="megamenu-heading">
                          <Link href={`/${item.slug}`} onClick={() => close()}>
                            {item.title}
                          </Link>
                          <Popover.Button
                            aria-expanded="true"
                            className={clsx('close-submenu', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
                          >
                            <Icon name="chevron-left" />
                          </Popover.Button>
                        </div>

                        <div className={clsx('menu-column', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
                          <h3 className="submenu-subtitle h6">{item.submenuPrimarySubtitle}</h3>
                          <ul>
                            {item.subMenuItems.map((submenuItem) => {
                              return (
                                <li key={submenuItem.name}>
                                  <Link
                                    href={submenuItem.link}
                                    className={clsx(
                                      'submenu-link body-5',
                                      HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                                    )}
                                    analytics={{
                                      context: ANALYTICS_CONTEXT,
                                      text: submenuItem.name,
                                    }}
                                    onClick={() => close()}
                                  >
                                    <Icon
                                      className={clsx(
                                        'fa-lg d-lg-none d-xl-block',
                                        HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                                      )}
                                      name={submenuItem.icon ? submenuItem.icon : 'angle-right'}
                                    />
                                    <span>{submenuItem.name}</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          className={clsx(
                            'menu-column bg-alt',
                            HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                          )}
                        >
                          <h3 className="submenu-subtitle h6">{item.submenuSecondarySubtitle}</h3>
                          <ul>
                            {item.secondarySubMenuItems.map((article) => {
                              return (
                                <li key={article.name}>
                                  <Link
                                    href={article.link}
                                    className={clsx(
                                      'submenu-link body-5',
                                      HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                                    )}
                                    analytics={{
                                      context: ANALYTICS_CONTEXT,
                                      text: article.name,
                                    }}
                                    onClick={() => close()}
                                  >
                                    <Icon
                                      className={clsx(
                                        'fa-lg d-lg-none d-xl-block',
                                        HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                                      )}
                                      name="angle-right"
                                    />

                                    <span>{article.name}</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                          <Link
                            href={item.submenuSecondaryLink}
                            className={clsx(
                              'view-all-link body-5',
                              HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                            )}
                            analytics={{
                              context: ANALYTICS_CONTEXT,
                              text: item.submenuSecondaryLinkText,
                            }}
                            onClick={() => close()}
                          >
                            <Icon className="fa-lg" name="angle-right" />
                            <span>{item.submenuSecondaryLinkText}</span>
                          </Link>
                        </div>
                      </div>
                      <Popover.Button
                        as="span"
                        className={clsx(
                          'fal fa-times icon-close',
                          HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                        )}
                        aria-hidden="true"
                      >
                        <span className="sr-only">close mega menu</span>
                      </Popover.Button>
                    </Popover.Panel>
                  </>
                );
              }}
            </Popover>
          );
        })}
      </Popover.Group>
    </nav>
  );
};

export { Navigation };
