import { dismissNotifier } from '@/utils/notify';
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

type Props = {
  id: string;
  message: string;
};

export const SuccessToast = ({ id, message }: Props): ReactElement => {
  return (
    <div className="success-toast--container">
      <div className="success-toast--check">
        <FontAwesomeIcon icon={faCheck} className="success-toast--check-icon" />
      </div>

      <div className="success-toast--right-container">
        <span>{message}</span>

        <div className="success-toast--seperator" />

        <button
          className="success-toast--close"
          type="button"
          aria-label={`Dismiss - ${message}`}
          onClick={() => dismissNotifier(id)}
        >
          <FontAwesomeIcon icon={faClose} className="success-toast--close-icon" />
        </button>
      </div>
    </div>
  );
};
