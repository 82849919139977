import { ReactElement } from 'react';

import { Navigation } from '@/components/footer/navigation';
import clsx from 'clsx';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const Footer = (): ReactElement => {
  const currentYear = new Date().getFullYear();

  return (
    <footer data-datocms-noindex className={clsx(HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
      <div id="footer" className="container">
        <div className="row">
          <div className="full-width">
            <Navigation />
            <div className="container-fluid">
              <div className="row">
                <div className="component rich-text col-12">
                  <div className="component-content">
                    <p className="body-7 mb-0">
                      © {currentYear} Royal Automobile Club of Tasmania (RACT) All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
