import type { FC } from 'react';

type IframeEmbedPropTypes = {
  height: string | number;
  width: string | number;
  provider: 'youtube' | 'vimeo';
  providerUid: string;
  title: string;
};

const videoServiceUrlMapping = {
  youtube: 'https://www.youtube.com/embed',
  vimeo: 'https://player.vimeo.com/video',
};

const IframeEmbed: FC<IframeEmbedPropTypes> = ({
  title,
  height = 1920,
  width = 1080,
  provider,
  providerUid,
}: IframeEmbedPropTypes) => {
  const baseUrl = videoServiceUrlMapping[provider];
  const iframeSrc = `${baseUrl}/${providerUid}`;

  return (
    <iframe
      width={width}
      height={height}
      src={iframeSrc}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export { IframeEmbed };
