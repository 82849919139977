import type { FC } from 'react';

import { captureException } from '@/utils/sentry';
import type { VideoType } from '@/lib/types';
import { IframeEmbed } from './iframe-embed';

const Video: FC<VideoType> = ({ title, height = 1920, width = 1080, provider, providerUid }: VideoType) => {
  if (provider !== 'youtube' && provider !== 'vimeo') {
    captureException(new Error('Invalid video provider; must be youtube or vimeo'), { extra: { provider } });
    return null;
  }

  return <IframeEmbed title={title} height={height} width={width} provider={provider} providerUid={providerUid} />;
};

export { Video };
