import { SuccessToast } from '@/components/success-toast';
import { vars } from '@/styles/theme/vars.css';
import { toast } from 'react-hot-toast';

const isNotifyEnabled = process.env.NEXT_PUBLIC_DATOCMS_PREVIEW_ENABLED === 'true';

type Options = {
  id?: string;
};

export const dismissNotifier = (id: string): void => {
  toast.dismiss(id);
};

const errorNotifify = (message: string, options: Options | undefined): void => {
  if (!isNotifyEnabled) return;

  toast.error(message, {
    duration: Infinity,
    style: {
      border: `1px solid red`,
      padding: '10px',
      fontSize: '14px',
      marginTop: '10px',
      marginLeft: '10px',
    },
    ...options,
  });
};

const successNotify = (message: string, options: Options | undefined): void => {
  toast((t) => <SuccessToast id={t.id} message={message} />, {
    position: 'bottom-center',
    duration: 5000,
    style: {
      backgroundColor: vars.color['gray-700'],
      maxWidth: '90%',
    },
    ...options,
  });
};

const notify = (message: string, type: 'error' | 'success', options: Options | undefined): void => {
  (type === 'success' ? successNotify : errorNotifify)(message, options);
};

export { notify };
