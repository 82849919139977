import { faChevronLeft, faChevronRight, faHome } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

import { Link } from '@/components/link';
import { PagePath, PagePaths } from '@/lib/types';
import { Breadcrumb, BreadcrumbNav, BreadcrumbItem, BreadcrumbItemLink } from './styles.css';

type BreadcrumbsProps = {
  paths: PagePaths;
};

const Breadcrumbs = ({ paths }: BreadcrumbsProps): ReactElement | null => {
  if (isEmpty(paths)) {
    return null;
  }

  const homePath: PagePath = { id: 'home', title: 'Home', path: '/', slug: '' };
  const breadcrumbs = [homePath, ...paths];

  return (
    <div className={`${Breadcrumb} component navigation-title col-12 body-7`} data-datocms-noindex>
      <nav className={BreadcrumbNav}>
        <ol className="breadcrumb-list">
          {breadcrumbs.map((path, index) => {
            const isHome = index === 0;
            const isParent = index === breadcrumbs.length - 2;

            return (
              <li className={BreadcrumbItem} key={path.id}>
                {!isHome && <FontAwesomeIcon className="mx-3 d-none d-lg-inline-block" icon={faChevronRight} />}
                <Link className={BreadcrumbItemLink} title={path.title} href={path.path}>
                  {isHome && <FontAwesomeIcon className="mr-1 d-none d-lg-inline-block" icon={faHome} />}
                  {isParent && <FontAwesomeIcon className="mr-3 d-lg-none" icon={faChevronLeft} />}
                  {path.title}
                </Link>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export { Breadcrumbs };
