import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { HamburgerSearchIcon } from '@/components/header/header-controls/hamburger-search-icon';
import { headerControlDataItems } from '@/components/header/data';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { Logo } from '@/components/logo';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

type HeaderControlsProps = {
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: Dispatch<SetStateAction<boolean>>;
};

const HeaderControls: FC<HeaderControlsProps> = ({ isMobileMenuActive, setIsMobileMenuActive }) => {
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuActive(!isMobileMenuActive);

    const mainContent = document.querySelector('main');
    const bodyContent = document.querySelector('body');
    const footer = document.querySelector('footer');

    if (!isMobileMenuActive) {
      mainContent?.classList.add('drawer-open');
      bodyContent?.classList.add('prevent-scroll');
      footer?.classList.add('drawer-open-footer');
    } else {
      mainContent?.classList.remove('drawer-open');
      bodyContent?.classList.remove('prevent-scroll');
      footer?.classList.remove('drawer-open-footer');
    }
  }, [isMobileMenuActive, setIsMobileMenuActive]);

  const onWindowResize = useCallback(() => {
    if (isMobileMenuActive) toggleMobileMenu();
  }, [isMobileMenuActive, toggleMobileMenu]);

  // Close mobile menu on resize of the window
  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return (
    <div className="header-controls">
      <div className="logo-container">
        <Link
          href="/"
          className="logo-link"
          title="Home"
          analytics={{
            context: 'Header',
            text: 'Home',
          }}
        >
          {HUNDRED_YEAR_TAKEOVER ? (
            <img
              id="hundred-year-logo"
              className="logo"
              src="/images/animated-ract-100-year-logo.gif"
              alt="RACT 100 year logo"
            />
          ) : (
            <Logo />
          )}
        </Link>
      </div>
      <div className={clsx('header-icon-links', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
        {headerControlDataItems.map((item) => {
          return (
            <Link
              href={item.link}
              className={clsx('header-icon-link', item.linkIcon)}
              title={item.title}
              key={item.title}
            >
              <span className="sr-only">{item.text}</span>
              <FontAwesomeIcon icon={item.icon} />
            </Link>
          );
        })}
        <button
          id="toggle-menu"
          className="toggle-menu js-toggle-menu"
          aria-label="menu"
          aria-haspopup="true"
          aria-expanded={isMobileMenuActive ? 'true' : 'false'}
          type="button"
          onClick={toggleMobileMenu}
        >
          <Icon name="times" className={clsx('icon-close', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)} />
          <HamburgerSearchIcon />
        </button>
      </div>
    </div>
  );
};

export { HeaderControls };
