import { FC } from 'react';
import { kebabCase } from 'lodash';
import clsx from 'clsx';

import { ColourStrings } from '@/lib/types';

export type IconType = {
  className?: string;
  name: string;
  colour?: ColourStrings | null;
};

const Icon: FC<IconType> = ({ className = null, name, colour = null }) => {
  const iconName = `fa-${name}`;
  const iconColour = colour ? `text-${kebabCase(colour)}` : null;
  const classes = className ? `fa-fw ${className}` : 'fa-fw';

  return <span className={clsx('icon', 'fal', classes, iconName, iconColour)} aria-hidden />;
};

export { Icon };
