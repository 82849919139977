import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { BlockHeader } from '@/components/block-header';
import { Video } from '@/components/video';
import type { VideoBlockType } from '@/lib/types';

const VideoBlock = ({ title, video, anchor }: VideoBlockType): ReactElement => (
  <BaseBlock className="video-block" width="Normal" anchor={anchor} hasContentPadding={false}>
    <BlockHeader title={title} />
    <div className="video-container">
      <Video {...video} />
    </div>
  </BaseBlock>
);

export { VideoBlock };
