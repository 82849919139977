import type { ReactElement } from 'react';

const Logo = (): ReactElement => {
  return (
    <svg className="logo" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196 196">
      <path d="M7.6 175.5a13 13 0 0012.9 12.9h155a13 13 0 0012.9-12.9v-10.2H7.6v10.2z" fill="#E22E2D" />
      <path d="M175.5 7.6h-155A13 13 0 007.6 20.5v137.2h180.9V20.5a13 13 0 00-13-12.9z" fill="#214C88" />
      <path
        d="M175.5 0h-155A20.4 20.4 0 000 20.5v155C0 186.9 9.1 196 20.5 196h155c11.4 0 20.5-9.1 20.5-20.5v-155C196 9.1 186.9 0 175.5 0zm12.9 175.5a13 13 0 01-12.9 12.9h-155a13 13 0 01-12.9-12.9v-10.2h180.9v10.2h-.1zM41 139l12.7 18.7H40.5V139h.5zm-.7-6v-14.7h3.3c7.3 0 8.9 2.2 8.9 6.5.1 5.3-3.9 8.2-12.2 8.2zm35.4 24.7l8.2-22.9 8.5 22.9H75.7zm112.7 0H163v-36.5h9.8v-10.9h-31.2v10.9h9.8v36.5H135c1.1-.4 2.2-.9 3.3-1.1V147c-3.1.9-5.6 1.3-8.5 1.1-7.8-.2-14.3-4.5-14.5-13.8-.2-5.6 2-13.8 13.4-14.7 4-.4 5.3.2 9.1 1.1v-10.2c-3.8-.7-4.9-1.1-8.9-1.1a25.5 25.5 0 00-25.8 24.7c0 12.2 6.9 20.7 17.2 23.6h-14.5L84 102.5l-19.6 49.7-11.1-14.5c6.2-2.7 9.8-7.8 9.8-13.1 0-8-4.7-14-19.6-14h-14V158H7.6V20.5A13 13 0 0120.5 7.6h155a13 13 0 0112.9 12.9v137.2z"
        fill="#fff"
      />
    </svg>
  );
};

export { Logo };
