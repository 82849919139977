import { faSearch, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, ReactElement } from 'react';

import { getListItemsClassName } from '@/utils/get-list-items-class-name';
import { headerUtilityItems } from '@/components/header/data';
import { Link } from '@/components/link';
import { HUNDRED_YEAR_TAKEOVER, URLS } from '@/lib/constants';
import { useAnalytics } from '@/lib/analytics';
import clsx from 'clsx';

const HeaderUtilities = (): ReactElement => {
  const { track } = useAnalytics();

  return (
    <div className={clsx('header-utilities', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
      <div
        className={clsx('component link-list utility-links', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
      >
        <div className="component-content">
          <ul>
            {headerUtilityItems.map((item, index) => {
              return (
                <li className={getListItemsClassName(index, headerUtilityItems.length)} key={item.name}>
                  <div className="field-link">
                    <Link
                      className="body-6"
                      href={item.link}
                      analytics={{
                        context: 'Quick Link',
                        text: item.name,
                      }}
                    >
                      {item.name}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="site-search">
        <form
          className="site-search-form"
          role="search"
          aria-labelledby="site-search-desc"
          action="/search"
          onSubmit={(event: FormEvent) => {
            const target = event.target as typeof event.target & {
              term: { value: string };
            };

            track('Site Searched', {
              text: target.term.value,
            });
          }}
        >
          <div className={clsx('search-input-wrapper', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="site-search-input" className="sr-only">
              Search this <span id="site-search-desc">site</span>
            </label>
            <input name="term" id="site-search-input" type="text" className="search-input" placeholder="Search..." />
            <button className="search-button" type="submit">
              <FontAwesomeIcon className="icon" icon={faSearch} />
              <span className="sr-only">Search</span>
            </button>
          </div>
        </form>
      </div>

      <div className={clsx('component cta-link member-hub', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
        <div className="component-content">
          <Link
            className="btn btn-xs btn-goal"
            href={URLS.RACT_HUB}
            analytics={{ context: 'CTA - Primary CTA', text: 'Login' }}
          >
            <FontAwesomeIcon className="mr-2" icon={faUser} />
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export { HeaderUtilities };
