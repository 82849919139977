/* eslint-disable react/require-default-props */

import { Image as DatoImage } from 'react-datocms';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import type { ResponsiveImageType } from 'react-datocms';

import { BackgroundImageStyle, ImageStyle, FullWidthContainer, FullWidthImage } from './styles.css';

type ImagePropTypes = {
  data?: null | ResponsiveImageType;
  className?: string;
  pictureClassName?: string;
  fadeInDuration?: number;
  intersectionThreshold?: number;
  intersectionMargin?: string;
  lazyLoad?: boolean;
  style?: React.CSSProperties;
  pictureStyle?: React.CSSProperties;
  explicitWidth?: boolean;
  focalPoint?: {
    x: number;
    y: number;
  };
  isBackground?: boolean;
  shouldHideBackground?: boolean;
};

const Image = ({
  className,
  data = null,
  fadeInDuration = 300,
  intersectionMargin = '200px 0px 0px 0px',
  isBackground = false,
  shouldHideBackground,
  ...props
}: ImagePropTypes): ReactElement | null => {
  // the presence of the data prop signifies the image has come from datoCMS
  // In these instances we use the datoCMS Image component
  // For usage see: https://www.datocms.com/docs/next-js/managing-images
  if (data) {
    const objectPosition = props.focalPoint ? `${props.focalPoint.x * 100}% ${props.focalPoint.y * 100}%` : '50% 50%';

    // @NOTE: Disabled for now because it produces a lot of errors. Either we need to go through and update all images in Dato media
    // library to have alt text or we are incorrectly not querying for alt text value when requesting images. Also sometimes
    // Dato doesn't update the graphql response quickly enough when you change/add the alt text in the CMS.
    // if (isEmpty(data.alt)) {
    //   notify(`Alt text missing for image: ${data.src}`, { id: `image-alt-${kebabCase(data.src)}` });
    // }
    return (
      <div
        className={clsx(
          { [BackgroundImageStyle]: isBackground },
          className,
          shouldHideBackground && FullWidthContainer
        )}
      >
        <DatoImage
          {...props}
          fadeInDuration={fadeInDuration}
          intersectionMargin={intersectionMargin}
          className={clsx(FullWidthImage, ImageStyle, { [BackgroundImageStyle]: isBackground })}
          data={data}
          pictureStyle={
            isBackground
              ? {
                  objectFit: 'cover',
                  objectPosition,
                }
              : undefined
          }
        />
      </div>
    );
  }

  return null;
};

export { Image };
