import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';
import clsx from 'clsx';
import { ReactElement } from 'react';

const HamburgerSearchIcon = (): ReactElement => {
  return (
    <svg
      className={clsx('hamburger-search', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 418.3 371.03"
    >
      <path d="M168.29 358.18l11.11 11.11a5.91 5.91 0 008.36 0l59.69-59.68a6.06 6.06 0 001.72-4.18v-6.49a102.25 102.25 0 10-10.52-10.52h-6.49a5.83 5.83 0 00-4.18 1.72l-59.69 59.68a5.91 5.91 0 000 8.36zm69.08-136.63a78.67 78.67 0 1178.66 78.67 78.64 78.64 0 01-78.66-78.67zM165.01 212.35h-161a4 4 0 00-4 4v15.93a4 4 0 004 4h160.86c-.26-3.65-.4-7.32-.4-11 0-4.36.19-8.66.54-12.93zM220.01 106.18h-216a4 4 0 00-4 4v15.92a4 4 0 004 4h193a157.71 157.71 0 0113-14.7q4.8-4.87 10-9.22z" />
      <rect width="297.29" height="23.89" rx="3.98" />
    </svg>
  );
};

export { HamburgerSearchIcon };
