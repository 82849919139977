type FooterDataItemsType = Array<{
  title: string;
  subMenuItems: Array<{
    name: string;
    link: string;
  }>;
}>;

const footerDataItems: FooterDataItemsType = [
  {
    title: 'Motoring',
    subMenuItems: [
      {
        name: 'Roadside Assistance',
        link: '/cars-and-driving/roadside-assistance',
      },
      {
        name: 'Driver Training',
        link: '/cars-and-driving/driver-training',
      },
      {
        name: 'Vehicle Inspections',
        link: '/cars-and-driving/vehicle-inspections',
      },
      {
        name: 'Batteries',
        link: '/cars-and-driving/batteries',
      },
      {
        name: 'AutoServe',
        link: '/cars-and-driving/autoserve',
      },
      {
        name: 'Car Seats',
        link: '/cars-and-driving/car-seats',
      },
      {
        name: 'Approved Repairers',
        link: '/cars-and-driving/approved-repairers',
      },
    ],
  },
  {
    title: 'Insurance',
    subMenuItems: [
      {
        name: 'Boat',
        link: '/insurance-and-finance/boat-insurance',
      },
      {
        name: 'Car',
        link: '/insurance-and-finance/car-insurance',
      },
      {
        name: 'Caravan & Trailer',
        link: '/insurance-and-finance/caravan-and-trailer-insurance',
      },
      {
        name: 'Home & Contents',
        link: '/insurance-and-finance/home-and-contents-insurance',
      },
      {
        name: 'Pet Insurance',
        link: '/insurance-and-finance/pet-insurance',
      },
      {
        name: 'Investor',
        link: '/insurance-and-finance/investor-insurance',
      },
      {
        name: 'Strata',
        link: '/insurance-and-finance/strata-insurance',
      },
    ],
  },
  {
    title: 'Loans',
    subMenuItems: [
      {
        name: 'Car loans',
        link: '/insurance-and-finance/car-loans',
      },
      {
        name: 'Personal Finance',
        link: '/insurance-and-finance/personal-finance',
      },
      {
        name: 'Loan Calculator',
        link: '/insurance-and-finance/personal-finance#loan-calculator',
      },
    ],
  },
  {
    title: 'Travel',
    subMenuItems: [
      {
        name: 'Holidays',
        link: '/travel-and-experience/holiday-here-this-year',
      },
      {
        name: 'Cruises',
        link: '/travel-and-experience/cruises',
      },
      {
        name: 'Corporate travel',
        link: '/travel-and-experience/corporate-travel',
      },
      {
        name: 'Travel Services',
        link: '/travel-and-experience/travel-services',
      },
      {
        name: 'Hot Deals',
        link: '/travel-and-experience/hot-deals',
      },
    ],
  },
  {
    title: 'About RACT',
    subMenuItems: [
      {
        name: 'About us',
        link: '/about-us',
      },
      {
        name: 'Contact us',
        link: '/getting-in-touch',
      },
      {
        name: 'Find a branch',
        link: '/our-locations',
      },
      {
        name: 'Opening hours',
        link: '/getting-in-touch',
      },
      {
        name: 'Careers',
        link: '/careers',
      },
      {
        name: 'Terms & Conditions',
        link: '/terms-and-conditions',
      },
      {
        name: 'Privacy',
        link: '/privacy',
      },
    ],
  },
];

export { footerDataItems };
