const getListItemsClassName = (index: number, numberOfItems: number): string => {
  let itemClass = `item${index} ${index % 2 === 0 ? 'odd' : 'even'}`;
  if (index === 0) {
    itemClass += ' first';
  } else if (index === numberOfItems - 1) {
    itemClass += ' last';
  }
  return itemClass;
};

export { getListItemsClassName };
