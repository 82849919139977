import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import { ReactElement } from 'react';
import clsx from 'clsx';

import { footerDataItems } from '@/components/footer/data';
import { getListItemsClassName } from '@/utils/get-list-items-class-name';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { HUNDRED_YEAR_TAKEOVER, SOCIAL_PLATFORMS } from '@/lib/constants';

const ANALYTICS_CONTEXT = 'Footer Link';

const Navigation = (): ReactElement => {
  return (
    <div className="footer-columns container-fluid">
      <div>
        <div className="row">
          {footerDataItems.map((item) => {
            const numberOfSubitems = item.subMenuItems.length;
            return (
              <div className="component link-list col-12" key={item.title}>
                <Disclosure>
                  {({ open }) => (
                    <div className="component-content">
                      <h3 className="footer-heading d-none d-md-block mb-3 body-7">{item.title}</h3>
                      <Disclosure.Button className="footer-heading py-2 d-flex justify-content-between align-items-center w-100 d-md-none body-7">
                        {item.title}
                        <Icon name={open ? 'chevron-up' : 'chevron-down'} />
                      </Disclosure.Button>
                      <Disclosure.Panel as="ul" className={clsx({ 'is-active': open })} static>
                        {item.subMenuItems.map((subMenuItem, index) => {
                          return (
                            <li className={getListItemsClassName(index, numberOfSubitems)} key={subMenuItem.name}>
                              <Link
                                className="body-7"
                                href={subMenuItem.link}
                                analytics={{
                                  context: ANALYTICS_CONTEXT,
                                  text: subMenuItem.name,
                                }}
                              >
                                {subMenuItem.name}
                              </Link>
                            </li>
                          );
                        })}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
            );
          })}

          <div className="component social-links col-12">
            <div className="component-content">
              <p className="footer-heading body-7">Connect with us</p>
              <div className="social-links">
                <ul className={clsx('hover-icons', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
                  {map(SOCIAL_PLATFORMS, (item) => (
                    <li key={item.title}>
                      <Link
                        title={item.title}
                        href={item.url}
                        analytics={{
                          context: ANALYTICS_CONTEXT,
                          text: item.title,
                        }}
                      >
                        <FontAwesomeIcon icon={item.icon} />
                        <span className="sr-only">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Navigation };
