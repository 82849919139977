import type { FC } from 'react';

import { BlockContext, BlockContextType } from '@/lib/contexts/block';
import { ContentBlockType } from '@/lib/types';
import { ImageBlock } from '@/components/image-block';
import { VideoBlock } from '@/components/video-block';
import { WidgetBlock } from '@/components/widget-block';

const getBlock: FC<ContentBlockType> = (record) => {
  switch (record.type) {
    case 'image_block': {
      return <ImageBlock {...record} />;
    }
    case 'video_block': {
      return <VideoBlock {...record} />;
    }
    case 'widget_block': {
      return <WidgetBlock {...record} />;
    }
    default:
      return null;
  }
};

const getRenderedBlock: FC<ContentBlockType> = (record, context: BlockContextType) => (
  <BlockContext.Provider value={context}>{getBlock(record)}</BlockContext.Provider>
);

export { getRenderedBlock };
