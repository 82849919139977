import { FC } from 'react';
import { isEmpty } from 'lodash';

export type ImageCaptionType = {
  variant: 'Default' | 'Hero';
  title?: string;
  author?: string;
};

const ImageCaption: FC<ImageCaptionType> = ({ variant, title = null, author = null }) => {
  if (isEmpty(title) && isEmpty(author)) {
    return null;
  }
  const showDelimiter = !isEmpty(title) && !isEmpty(author);
  if (variant === 'Hero') {
    return (
      <div className="byline-wrapper body-7 py-1">
        {!isEmpty(title) && <div className="field-herocaption">{title}</div>}
        {showDelimiter && <div className="hero-delimiter">|</div>}
        {!isEmpty(author) && <div className="field-herophotographer">Image: {author}</div>}
      </div>
    );
  }
  return (
    <figcaption className="body-7">
      {!isEmpty(title) && title}
      {!isEmpty(author) && <p>Credit: {author}</p>}
    </figcaption>
  );
};

export { ImageCaption };
